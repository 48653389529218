<template>
  <canvas :height="height" :width="width" id="dossiers-par-etape"></canvas>
</template>
<script>
import {SOLUTIA} from "@/constants/Colors";
import {Chart} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: "DossierCount",
  props: {
    data: Object,
    height: {
      type: Number,
      default: 600,
    },
    width: {
      type: Number,
      default: 600,
    }
  },
  mounted: async function () {
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }
    // eslint-disable-next-line no-unused-vars
    const chartData = {
      label: "Nombre de dossiers par étape",
      labels: Object.keys(this.data),
      datasets: [{
        data: Object.values(this.data),
        datalabels: {
          color: '#ffffff',
          font: {
            size: 24
          }
        },
        backgroundColor: [...SOLUTIA, ...SOLUTIA, ...SOLUTIA]
      }],
    }
    // eslint-disable-next-line no-unused-vars
    const config = {
      onClick: this.handleStatutClick,
      title: {
        display: true,
        text: "Nombre de dossiers par étape",
        fontSize: 16,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          },
          gridLines: {
            drawBorder: false,
          }
        }],
        xAxes: [{
          gridLines: {
            display: false,
          }
        }]
      },

    }
    let that = this;
    this.$nextTick(function () {
      const ctx = document.getElementById('dossiers-par-etape');
      that.chart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "bar",
        //...defaultData
        data: JSON.parse(JSON.stringify(chartData)),
        options: JSON.parse(JSON.stringify(config)),
      });
    })
  },
  data: function () {
    return {
      chart: null
    }
  },
  computed: {
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
  },
  methods: {
    handleStatutClick(point, elementClicked) {
      if (elementClicked.length > 0) {
        let element = elementClicked[0];
        let statutName = Object.keys(this.data)[element._index];
        let statut = this.statuts.find((statut) => statut.name === statutName);
        this.$router.push(`/dossier?statut=${statut.id}`);
      }
    }
  }
}
</script>

<style scoped>

</style>