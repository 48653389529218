<template>
  <div>
    <h1 class="primary--text text-center">Messages importants</h1>
    <v-sheet v-if="!loading" class="overflow-y-auto">
      <v-card v-for="message in important_messages"  :key="message.id" color="white" elevation="1">
        <v-card-title class="pb-0  primary--text">
          {{message.fullname}}
        </v-card-title>
        <v-card-text class="pb-0">
          <p class="body-1 mb-0 black--text">{{ message.message}}</p>
        </v-card-text>
        <v-card-actions>
          <span class="grey--text">{{getReadableDateTime(message.date_creation)}}</span>
          <v-spacer></v-spacer>
          <router-link :to="`/dossier/${message.dossier}`">Dossier de  {{ message.beneficiaire_name}}</router-link>
        </v-card-actions>
      </v-card>
    </v-sheet>
    <v-row class="align-content-stretch" style="height: 400px" no-gutters justify="center" align="center" v-else>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-row>
  </div>
</template>
<script>
import {CommentaireRepository} from "@/repository/commentaire";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'ImportantMessages',
  mixins: [DateUtilMixin],
  async mounted() {
    let repository = new CommentaireRepository();
    this.important_messages = await repository.getImportantMessages();
    this.loading = false;
  },
  data() {
    return {
      important_messages: [],
      loading: true,
    }
  },

}
</script>