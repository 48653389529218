<template>
    <div>
    <h1 class="primary--text text-center">Documents expirés</h1>
    <v-sheet v-if="!loading" class="overflow-y-auto">
      <v-data-iterator :items="expiredDocuments" item-key="id" :items-per-page="4">
        <template v-slot:item="{item}">
          <v-card class="mb-2" color="white" elevation="1">
            <v-card-title class="pb-0  primary--text">
              <router-link class="text-decoration-none" :to="`/entreprises/${item.entreprise}`">
                {{ item.type }}
              </router-link>
            </v-card-title>
            <v-card-text class="pb-0">
              <p class="body-1 mb-0 black--text">Expiré depuis {{ getReadableDate(item.date_validite) }}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <router-link :to="`/entreprises/${item.entreprise}`">{{ item.entreprise_name }}</router-link>
            </v-card-actions>
          </v-card>
        </template>
      </v-data-iterator>
    </v-sheet>
    <v-row class="align-content-stretch" style="height: 400px" no-gutters justify="center" align="center" v-else>
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-row>
  </div>
</template>
<script>
import {EntrepriseAttachmentRepository} from "@/repository/entreprises";
import dayjs from "dayjs";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'ExpiredDocuments',
  mixins: [DateUtilMixin],
  async mounted() {
    this.loading = true;
    let repo = new EntrepriseAttachmentRepository();
    this.expiredDocuments = (await repo.fetch()).filter(function (document) {
      return document.archived === false && document.date_validite !== null && dayjs(document.date_validite).isBefore(dayjs());
    })
    this.loading = false;
  },
  data() {
    return {
      expiredDocuments: [],
      loading: false,
    }
  },

}
</script>